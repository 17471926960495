<template>
  <div
    class="fleetDashboard"
    :class="[
      {'fleetDashboard--detail' : fleetType === 'detail'},
      {'fleetDashboard--new' : fleetType === 'new'},
      {'fleetDashboard--provider' : dashboardType === 'provider'}
      ]"
  >
    <vue-scroll :ops="ops">
      <div v-if="fleetType === 'detail'" class="fleetDashboard__title">Aircraft {{ aircraftData.tailNumber }}</div>
      <div v-else class="fleetDashboard__title">New aircraft</div>
      <div class="fleetDashboard__form" :class="'fleetDashboard__form--'">
        <form
          class="form form--fleet js-fleetForm"
          :class="[{'loading' : loading}]"
          @submit.prevent="submitHandler"
        >
          <div class="form__labelGroup form__labelGroup--1">
            <div class="fleetDashboard__photos">
              <div v-if="fleetType === 'detail'" class="fleetDashboard__photos-header">
                <div class="fleetDashboard__photos-title">Aircraft photos</div>
                <!--                <button-->
                <!--                  class="form__button form__button&#45;&#45;edit js-editForm"-->
                <!--                  type="button"-->
                <!--                  @click="edit = true"-->
                <!--                >-->
                <!--                  <span class="text">Change info</span>-->
                <!--                </button>-->
                <!--                <div class="form__link form__link&#45;&#45;edit js-editForm" @click="edit = true">Change info</div>-->
              </div>
              <div v-else class="fleetDashboard__photos-header">
                <div class="fleetDashboard__photos-title">Upload photos</div>
              </div>
              <div class="fleetDashboard__photos-left">
                <div class="fleetDashboard__photos-slider js-aircraftPhotos">
                  <div class="swiper-container swiper-top" ref="swiperTop">
                    <!--                    <div class="swiper-wrapper" v-if="fleetType === 'detail'">-->
                    <!--                      <div-->
                    <!--                        class="swiper-slide"-->
                    <!--                        v-for="(item, index) of aircraftData.images"-->
                    <!--                        :key="index"-->
                    <!--                        :data-id="index + 1"-->
                    <!--                      >-->
                    <!--                        <span-->
                    <!--                          class="fleetDashboard__photos-slider-item"-->
                    <!--                          :style="{backgroundImage: `url(${item.url})`}"-->
                    <!--                        >-->
                    <!--                          <div-->
                    <!--                            class="fleetDashboard__photos-slider-img"-->
                    <!--                            @click="openLightboxOnSlide(activeSlide)"-->
                    <!--                          >-->
                    <!--                            <div v-if="fleetType === 'detail'" class="fleetDashboard__photos-slider-img-back">-->
                    <!--                              <span class="fleetDashboard__photos-slider-img-icon">-->
                    <!--                                <svg width="488.85" height="488.85" viewBox="0 0 488.85 488.85">-->
                    <!--                                  <defs>-->
                    <!--                                    <linearGradient id="eyeG" x1="244.425" y1="0" x2="244.425" y2="488.85"-->
                    <!--                                                    gradient-units="userSpaceOnUse">-->
                    <!--                                      <stop stop-color="#41FB96"/><stop offset="1" stop-color="#12A053"/>-->
                    <!--                                    </linearGradient>-->
                    <!--                                  </defs>-->
                    <!--                                  <path-->
                    <!--                                    d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"-->
                    <!--                                    fill="url(#eyeG)"/>-->
                    <!--                                </svg>-->
                    <!--                              </span>-->
                    <!--                            </div>-->
                    <!--                          </div>-->
                    <!--                          <label class="form__file form__file&#45;&#45;photos js-file-img">-->
                    <!--                            <input-->
                    <!--                              type="file"-->
                    <!--                              name="photo"-->
                    <!--                              accept="image/*"-->
                    <!--                            />-->
                    <!--                            <div class="form__file-remove js-file-remove hide" title="Delete photo">-->
                    <!--                              <SvgIcon name="rubbish-bin"/>-->
                    <!--                            </div>-->
                    <!--                            <div class="form__file-icon">-->
                    <!--                              <SvgIcon name="photo-camera"/>-->
                    <!--                            </div>-->
                    <!--                            <div class="form__file-caption js-file-name">Upload photo</div>-->
                    <!--                          </label>-->
                    <!--                        </span>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <div class="swiper-wrapper">
                      <div
                        class="swiper-slide"
                        v-for="(item, index) of aircraft.galleryDescr"
                        :key="index"
                        :data-id="index + 1"
                      >
                        <span
                          class="fleetDashboard__photos-slider-item"
                        >
                          <div
                            class="fleetDashboard__photos-slider-img"
                            @click="openLightboxOnSlide(activeSlide)"
                            v-if="aircraftData.images.filter((img, i) => img.order === index + 1 && !img.fileSrc).length === 1"
                            :style="aircraftData.images.filter((img, i) => img.order === index + 1 && !img.fileSrc).length === 1 ? {backgroundImage: `url(${aircraftData.images.filter((img, i) => img.order === index + 1 && !img.fileSrc)[0].url})`} : ''"
                          >
                            <div v-if="fleetType === 'detail'" class="fleetDashboard__photos-slider-img-back">
                              <span class="fleetDashboard__photos-slider-img-icon">
                                <svg width="488.85" height="488.85" viewBox="0 0 488.85 488.85">
                                  <defs>
                                    <linearGradient id="eyeG" x1="244.425" y1="0" x2="244.425" y2="488.85"
                                                    gradient-units="userSpaceOnUse">
                                      <stop stop-color="#41FB96"/><stop offset="1" stop-color="#12A053"/>
                                    </linearGradient>
                                  </defs>
                                  <path
                                    d="M244.425,98.725c-93.4,0-178.1,51.1-240.6,134.1c-5.1,6.8-5.1,16.3,0,23.1c62.5,83.1,147.2,134.2,240.6,134.2s178.1-51.1,240.6-134.1c5.1-6.8,5.1-16.3,0-23.1C422.525,149.825,337.825,98.725,244.425,98.725z M251.125,347.025c-62,3.9-113.2-47.2-109.3-109.3c3.2-51.2,44.7-92.7,95.9-95.9c62-3.9,113.2,47.2,109.3,109.3C343.725,302.225,302.225,343.725,251.125,347.025z M248.025,299.625c-33.4,2.1-61-25.4-58.8-58.8c1.7-27.6,24.1-49.9,51.7-51.7c33.4-2.1,61,25.4,58.8,58.8C297.925,275.625,275.525,297.925,248.025,299.625z"
                                    fill="url(#eyeG)"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                          <label v-else class="form__file form__file--photos js-file-img"
                                 :class="{'preview' : aircraftData.images.filter((img, i) => img.order === index + 1).length === 1}"
                                 :style="aircraftData.images.filter((img, i) => img.order === index + 1).length === 1 ? {backgroundImage: `url(${aircraftData.images.filter((img, i) => img.order === index + 1)[0].url})`} : ''">
                            <input
                              type="file"
                              name="photo"
                              accept="image/*"
                            />
                            <div class="form__file-remove js-file-remove"
                                 title="Delete photo">
                              <SvgIcon name="rubbish-bin"/>
                            </div>
                            <div class="form__file-icon">
                              <SvgIcon name="photo-camera"/>
                            </div>
                            <div class="form__file-caption js-file-name">{{
                                aircraftData.images[index] && aircraftData.images[index].order === index + 1 ? aircraftData.images[index].name : 'Upload photo'
                              }}</div>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-pagination"></div>
                </div>
                <FsLightbox
                  :toggler="toggler"
                  :slide="activeSlide"
                  :sources="aircraftData.images.map(i => i.url)"
                  type='image'
                  :onOpen="opened"
                />
              </div>
              <div class="fleetDashboard__photos-right js-aircraftPhotos-caption-box">
                <div class="fleetDashboard__photos-subtitle">Photo requirements:</div>
                <div class="fleetDashboard__photos-list">
                  <div
                    v-for="(item, index) of aircraft.galleryDescr"
                    :key="index"
                    class="fleetDashboard__photos-list-item js-aircraftPhotos-caption"
                    :class="{'active' : activeSlide === index + 1}"
                  >
                    <div @click="changeSlideRequirements(index)">
                      {{ item.count }}
                      <b>{{ item.descr }}</b>
                    </div>
                    <span class="deleteImageButton" @click="showImageModal(index)" v-if="isRemoveVisible(index)">delete</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form__labelGroup form__labelGroup--2">
            <label class="form__label form__label--number">
              <div class="form__caption">Tail number:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="aircraftData.tailNumber"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
              <span v-if="$v.aircraftData.tailNumber.$dirty && !$v.aircraftData.tailNumber.required"
                    class="form__field-invalid">
                This field is required
              </span>
            </label>
            <label class="form__label form__label--select">
              <div class="form__caption">Aircraft type</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  data-type="aircraftType"
                  @input="searchResultsFleet"
                  @focus="showTypeheadMenu('aircraftType')"
                  @blur="hideTypeheadMenu"
                  v-model="aircraftData.type"
                  :disabled="loading"
                  autocomplete="off"
                />
                <div
                  class="typeahead-menu typeahead-empty"
                  :class="{'typeahead-open' : typeheadMenu === 'aircraftType'}"
                >
                  <div class="typeahead-dataset typeahead-dataset-types">
                    <div
                      class="typeahead-suggestion typeahead-selectable"
                      data-type="select-aircraftType"
                      v-for="(item, index) of shortListAircraftType" :key="index"
                      @click="selectAircraftOptions(item)"
                    >{{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </label>
            <label class="form__label form__label--select">
              <div class="form__caption">Aircraft model</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field js-typeahead"
                  type="text"
                  data-type="aircraftModel"
                  @input="searchResultsFleet"
                  @focus="showTypeheadMenu('aircraftModel')"
                  @blur="hideTypeheadMenu"
                  v-model="aircraftData.model"
                  :disabled="loading"
                />
                <div
                  class="typeahead-menu typeahead-empty"
                  :class="{'typeahead-open' : typeheadMenu === 'aircraftModel'}"
                >
                  <div class="typeahead-dataset typeahead-dataset-types">
                    <div
                      class="typeahead-suggestion typeahead-selectable"
                      data-type="select-aircraftModel"
                      v-for="(item, index) of shortListAircraftModel" :key="index"
                      @click="selectAircraftOptions(item)"
                    >{{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="$v.aircraftData.model.$dirty && !$v.aircraftData.model.required" class="form__field-invalid">
                This field is required
              </span>
            </label>
            <label class="form__label form__label--select">
              <div class="form__caption">Home base:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field js-typeahead"
                  type="text"
                  data-type="homeBase"
                  @input="searchResultsFleet"
                  @focus="showTypeheadMenu('homeBase')"
                  @blur="hideTypeheadMenu"
                  v-model="aircraftData.homeBase"
                  :disabled="loading"
                />
                <div
                  class="typeahead-menu typeahead-empty"
                  :class="{'typeahead-open' : typeheadMenu === 'homeBase'}"
                >
                  <div class="typeahead-dataset typeahead-dataset-types">
                    <div
                      class="typeahead-suggestion typeahead-selectable"
                      data-type="select-homeBase"
                      v-for="(item, index) of shortListHomeBase" :key="index"
                      @click="selectAircraftOptions(item)"
                    >{{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
              <span v-if="$v.aircraftData.homeBase.$dirty && !$v.aircraftData.homeBase.required"
                    class="form__field-invalid">
                This field is required
              </span>
            </label>
          </div>

          <div class="form__labelGroup form__labelGroup--4">
            <label class="form__label form__label--text">
              <div class="form__caption">Argus safety rating:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="aircraftData.argusSafetyRating"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
            </label>
            <label class="form__label form__label--text">
              <div class="form__caption">Wyvern safety rating:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="aircraftData.wyvernSafetyRating"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
            </label>
            <label class="form__label form__label--number">
              <div class="form__caption">Year of manufacture:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="aircraftData.manufactureYear"
                  :disabled="loading"
                  v-mask="'####'"
                  minlength="4"
                  autocomplete="off"
                />
              </div>
            </label>
          </div>

          <div class="form__labelGroup form__labelGroup--5">
            <label class="form__label form__label--number">
              <div class="form__caption">Last interior<br>refurbish:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="aircraftData.lastIntRefurbish"
                  v-mask="'####'"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
            </label>
            <label class="form__label form__label--number">
              <div class="form__caption">Last exterior<br>refurbish:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="aircraftData.lastExtRefurbish"
                  v-mask="'####'"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
            </label>
            <label class="form__label form__label--number">
              <div class="form__caption">Maximum<br>passengers:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="aircraftData.MaxPassengers"
                  :disabled="loading"
                  v-mask="'###'"
                  autocomplete="off"
                />
              </div>
              <span v-if="$v.aircraftData.MaxPassengers.$dirty && !$v.aircraftData.MaxPassengers.required"
                    class="form__field-invalid">
                This field is required
              </span>
            </label>

            <div class="form__radioGroup js-tooltip-box">
              <div class="form__caption">Spaces available?</div>
              <div class="form__tooltip js-tooltip" :class="{'show' : showSpacestooltip}">
                <label class="form__label form__label--number">
                  <div class="form__caption">Number of spaces:</div>
                  <div class="form__field-wrapper">
                    <input
                      class="form__field js-numberOnly"
                      type="text"
                      v-model="aircraftData.spaces"
                      v-mask="'###'"
                      :disabled="loading"
                      autocomplete="off"
                    />
                  </div>
                </label>
              </div>
              <label class="form__label form__label--radio" :class="{'checked' : aircraftData.isSpacesAvailable===true}">
                <input
                  class="form__radio js-tooltip-input"
                  type="radio"
                  v-model="aircraftData.isSpacesAvailable"
                  :value="true"
                />
                <span class="form__radio-box form__radio-box--yes">Yes</span>
              </label>
              <label class="form__label form__label--radio" :class="{'checked' : aircraftData.isSpacesAvailable===false}">
                <input
                  class="form__radio"
                  type="radio"
                  v-model="aircraftData.isSpacesAvailable"
                  :value="false"
                />
                <span class="form__radio-box form__radio-box--no">No</span>
              </label>
            </div>
          </div>

          <div class="form__labelGroup form__labelGroup--6">
            <label class="form__label form__label--number">
              <div class="form__caption">Hours flown:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field js-numberOnly"
                  type="text"
                  v-model="aircraftData.hoursFlown"
                  v-mask="'######'"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
            </label>
            <label class="form__label form__label--number">
              <div class="form__caption">Fuel burn (gallons/hour):</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field js-numberOnly"
                  type="text"
                  v-model="aircraftData.fuelBurn"
                  v-mask="'######'"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
              <span v-if="$v.aircraftData.fuelBurn.$dirty && !$v.aircraftData.fuelBurn.required"
                    class="form__field-invalid">
                This field is required
              </span>
            </label>
          </div>

          <!--          <div class="form__title">Range:</div>-->
          <div class="form__labelGroup form__labelGroup--7">
            <label class="form__label form__label--number">
              <div class="form__caption">Speed (nautical miles/hour):</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field js-numberOnly"
                  type="text"
                  v-model="aircraftData.speed"
                  v-mask="'####'"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
              <span v-if="$v.aircraftData.speed.$dirty && !$v.aircraftData.speed.required" class="form__field-invalid">
                This field is required
              </span>
            </label>
            <label class="form__label form__label--number">
              <div class="form__caption">Range (nautical miles):</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field js-numberOnly"
                  type="text"
                  v-model="aircraftData.range"
                  v-mask="'#####'"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
              <span v-if="$v.aircraftData.range.$dirty && !$v.aircraftData.range.required" class="form__field-invalid">
                This field is required
              </span>
            </label>
            <label class="form__label form__label--number">
              <div class="form__caption">Cargo capability (lbs.):</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field js-numberOnly"
                  type="text"
                  v-model="aircraftData.cargoCapability"
                  v-mask="'#####'"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
            </label>
          </div>

          <div class="form__labelGroup form__labelGroup--8">
            <div class="form__radioGroup">
              <div class="form__caption">Wi-Fi onboard?</div>
              <label class="form__label form__label--radio" :class="{'checked' : aircraftData.wifi===true}">
                <input
                  class="form__radio"
                  type="radio"
                  v-model="aircraftData.wifi"
                  :value="true"
                />
                <span class="form__radio-box form__radio-box--yes">Yes</span>
              </label>
              <label class="form__label form__label--radio" :class="{'checked' : aircraftData.wifi===false}">
                <input
                  class="form__radio"
                  type="radio"
                  v-model="aircraftData.wifi"
                  :value="false"
                />
                <span class="form__radio-box form__radio-box--no">No</span>
              </label>
            </div>

            <div class="form__radioGroup js-tooltip-box">
              <div class="form__caption">Television onboard?</div>
              <div class="form__tooltip js-tooltip" :class="{'show' : showTVtooltip}">
                <label class="form__label form__label--number">
                  <div class="form__caption">Number of Televisions Onboard:</div>
                  <div class="form__field-wrapper">
                    <input
                      class="form__field js-numberOnly"
                      type="text"
                      v-model="aircraftData.numberOfTelevision"
                      v-mask="'###'"
                      :disabled="loading"
                      autocomplete="off"
                    />
                  </div>
                </label>
              </div>
              <label class="form__label form__label--radio" :class="{'checked' : aircraftData.tv===true}">
                <input
                  class="form__radio js-tooltip-input"
                  type="radio"
                  v-model="aircraftData.tv"
                  :value="true"
                />
                <span class="form__radio-box form__radio-box--yes">Yes</span>
              </label>
              <label class="form__label form__label--radio" :class="{'checked' : aircraftData.tv===false}">
                <input
                  class="form__radio"
                  type="radio"
                  v-model="aircraftData.tv"
                  :value="false"
                />
                <span class="form__radio-box form__radio-box--no">No</span>
              </label>
            </div>
          </div>

          <div class="form__title">Aircraft documents</div>
          <div class="form__labelGroup form__labelGroup--3">
            <div class="form__label form__label--file js-file">
              <div class="form__caption">Airworthiness certificate</div>
              <label class="form__file">
                <div class="form__file-button-wr js-file-button-sertificate">
                  <button class="form__file-button" type="button">
                    <span class="form__file-button-icon"><SvgIcon name="cloud"/></span>
                    <span class="form__file-button-text">Upload</span>
                  </button>
                </div>
                <div class="form__file-preview-wr js-file-preview-sertificate">
                  <div class="form__file-preview">
                  <span class="form__file-preview-remove js-file-remove" @click="showModal('sertificate')">
                    <SvgIcon name="close"/>
                  </span>
                    <div class="form__file-preview-icon">
                      <SvgIcon name="file"/>
                    </div>
                    <div class="form__file-preview-caption">
                      <div class="form__file-preview-title js-file-caption">{{ aircraftData.sertificate }}</div>
                    </div>
                  </div>
                </div>
                <input
                  class="form__field"
                  type="file"
                  ref="sertificate"
                  value="Upload photo"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  @change="getFileSertificate"
                />
              </label>
            </div>
            <div class="form__label form__label--file js-file">
              <div class="form__caption">Insurance documents</div>
              <label class="form__file">
                <div class="form__file-button-wr js-file-button-insurance">
                  <button class="form__file-button" type="button">
                    <span class="form__file-button-icon"><SvgIcon name="cloud"/></span>
                    <span class="form__file-button-text">Upload</span>
                  </button>
                </div>
                <div class="form__file-preview-wr js-file-preview-insurance">
                  <div class="form__file-preview">
                  <span class="form__file-preview-remove js-file-remove" @click="showModal('insurance')">
                    <SvgIcon name="close"/>
                  </span>
                    <div class="form__file-preview-icon">
                      <SvgIcon name="file"/>
                    </div>
                    <div class="form__file-preview-caption">
                      <div class="form__file-preview-title js-file-caption">{{ aircraftData.insurance }}</div>
                    </div>
                  </div>
                </div>
                <input
                  class="form__field"
                  type="file"
                  ref="insurance"
                  value="Upload photo"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  @change="getFileInsurance"
                />
              </label>
            </div>
          </div>

          <!--          <div class="form__labelGroup form__labelGroup&#45;&#45;9">-->
          <!--            <label class="form__label form__label&#45;&#45;number">-->
          <!--              <div class="form__caption">Maximum number of spaces/seats:</div>-->
          <!--              <div class="form__field-wrapper">-->
          <!--                <input-->
          <!--                  class="form__field"-->
          <!--                  type="text"-->
          <!--                  v-model="aircraftData.maxSeats"-->
          <!--                  :disabled="loading"-->
          <!--                  placeholder="Enter number here"-->
          <!--                  data-mask="0000"-->
          <!--                  autocomplete="off"-->
          <!--                  required="required"-->
          <!--                />-->
          <!--              </div>-->
          <!--            </label>-->
          <!--            <label class="form__label form__label&#45;&#45;number">-->
          <!--              <div class="form__caption">Minimum number of spaces/seats:</div>-->
          <!--              <div class="form__field-wrapper">-->
          <!--                <input-->
          <!--                  class="form__field"-->
          <!--                  type="text"-->
          <!--                  v-model="aircraftData.minSeats"-->
          <!--                  :disabled="loading"-->
          <!--                  placeholder="Enter number here"-->
          <!--                  data-mask="0000"-->
          <!--                  autocomplete="off"-->
          <!--                  required="required"-->
          <!--                />-->
          <!--              </div>-->
          <!--            </label>-->
          <!--          </div>-->

          <!--          <div class="form__labelGroup form__labelGroup&#45;&#45;10">-->
          <!--            <div class="form__radioGroup">-->
          <!--              <label class="form__label form__label&#45;&#45;radio">-->
          <!--                <input-->
          <!--                  class="form__radio js-price-radio"-->
          <!--                  type="radio"-->
          <!--                  v-model="aircraftData.price"-->
          <!--                  value="all-price"-->
          <!--                  data-type="all-price"-->
          <!--                />-->
          <!--                <i class="form__radio-icon"></i>-->
          <!--                <span class="form__radio-box">Same price for all spaces/seats:</span>-->
          <!--              </label>-->
          <!--              <label class="form__label form__label&#45;&#45;price price">-->
          <!--                <div class="form__field-wrapper">-->
          <!--                  <input-->
          <!--                    ref="allPrice"-->
          <!--                    class="form__field js-price-input"-->
          <!--                    type="text"-->
          <!--                    v-model="aircraftData.allPrice"-->
          <!--                    :disabled="loading"-->
          <!--                    v-money="money"-->
          <!--                    data-type="all-price"-->
          <!--                    placeholder="Enter price in $"-->
          <!--                    autocomplete="off"-->
          <!--                  />-->
          <!--                </div>-->
          <!--              </label>-->
          <!--            </div>-->
          <!--            <div class="form__radioGroup">-->
          <!--              <label class="form__label form__label&#45;&#45;radio">-->
          <!--                <input-->
          <!--                  class="form__radio js-price-radio"-->
          <!--                  type="radio"-->
          <!--                  v-model="aircraftData.price"-->
          <!--                  value="price-per-seat"-->
          <!--                  data-type="price-per-seat"-->
          <!--                />-->
          <!--                <i class="form__radio-icon"></i>-->
          <!--                <span class="form__radio-box">Same price per space/seat:</span>-->
          <!--              </label>-->
          <!--              <label class="form__label form__label&#45;&#45;price price">-->
          <!--                <div class="form__field-wrapper">-->
          <!--                  <input-->
          <!--                    ref="pricePerSeat"-->
          <!--                    class="form__field js-price-input"-->
          <!--                    type="text"-->
          <!--                    v-model="aircraftData.pricePerSeat"-->
          <!--                    :disabled="loading"-->
          <!--                    v-money="money"-->
          <!--                    placeholder="Enter price in $"-->
          <!--                    data-type="price-per-seat"-->
          <!--                    autocomplete="off"-->
          <!--                  />-->
          <!--                </div>-->
          <!--              </label>-->
          <!--            </div>-->
          <!--          </div>-->

          <!--          <div class="form__labelGroup form__labelGroup&#45;&#45;11">-->
          <!--            <div class="form__label form__label&#45;&#45;file js-file">-->
          <!--              <div class="form__caption">Seatind chart</div>-->
          <!--              <label class="form__file">-->
          <!--                <div class="form__file-button-wr js-file-button-seating">-->
          <!--                  <button class="form__file-button" type="button">-->
          <!--                    <span class="form__file-button-icon"><SvgIcon name="cloud"/></span>-->
          <!--                    <span class="form__file-button-text">Upload</span>-->
          <!--                  </button>-->
          <!--                </div>-->
          <!--                <div class="form__file-preview-wr js-file-preview-seating">-->
          <!--                  <div class="form__file-preview">-->
          <!--                    <span class="form__file-preview-remove js-file-remove" @click="showModal('seatingChart')">-->
          <!--                      <SvgIcon name="close"/>-->
          <!--                    </span>-->
          <!--                    <div class="form__file-preview-icon">-->
          <!--                      <SvgIcon name="file"/>-->
          <!--                    </div>-->
          <!--                    <div class="form__file-preview-caption">-->
          <!--                      <div class="form__file-preview-title js-file-caption">{{aircraftData.seatingChart}}</div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <input-->
          <!--                  class="form__field"-->
          <!--                  type="file"-->
          <!--                  ref="seatingChart"-->
          <!--                  value="Upload photo"-->
          <!--                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"-->
          <!--                  @change="getFileSeatingChart"-->
          <!--                />-->
          <!--              </label>-->
          <!--            </div>-->
          <!--            <div class="form__label form__label&#45;&#45;file js-file">-->
          <!--              <div class="form__caption">Specify prices</div>-->
          <!--              <label class="form__file">-->
          <!--                <div class="form__file-button-wr js-file-button-prices">-->
          <!--                  <button class="form__file-button" type="button">-->
          <!--                    <span class="form__file-button-icon"><SvgIcon name="cloud"/></span>-->
          <!--                    <span class="form__file-button-text">Upload</span>-->
          <!--                  </button>-->
          <!--                </div>-->
          <!--                <div class="form__file-preview-wr js-file-preview-prices">-->
          <!--                  <div class="form__file-preview">-->
          <!--                    <span class="form__file-preview-remove js-file-remove" @click="showModal('pricesFile')">-->
          <!--                      <SvgIcon name="close"/>-->
          <!--                    </span>-->
          <!--                    <div class="form__file-preview-icon">-->
          <!--                      <SvgIcon name="file"/>-->
          <!--                    </div>-->
          <!--                    <div class="form__file-preview-caption">-->
          <!--                      <div class="form__file-preview-title js-file-caption">{{aircraftData.pricesFile}}</div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <input-->
          <!--                  class="form__field"-->
          <!--                  type="file"-->
          <!--                  ref="pricesFile"-->
          <!--                  value="Upload photo"-->
          <!--                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"-->
          <!--                  @change="getFilePrices"-->
          <!--                />-->
          <!--              </label>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="form__labelGroup form__labelGroup--12">
            <label class="form__label form__label--price price">
              <div class="form__title">Price per hour:</div>
              <!--              <div class="form__subtitle">May be altered on a per-trip basis</div>-->
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="aircraftData.pricePerHour"
                  v-mask="'########'"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
              <span v-if="$v.aircraftData.pricePerHour.$dirty && !$v.aircraftData.pricePerHour.required"
                    class="form__field-invalid">
                This field is required
              </span>
            </label>
            <label class="form__label form__label--price price">
              <div class="form__title">EMPTY LEGS PRICE PER HOUR:</div>
              <!--              <div class="form__subtitle">May be altered on a per-trip basis</div>-->
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="aircraftData.emptyLegsPricePerHour"
                  v-mask="'########'"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
              <span
                v-if="$v.aircraftData.emptyLegsPricePerHour.$dirty && !$v.aircraftData.emptyLegsPricePerHour.required"
                class="form__field-invalid">
                This field is required
              </span>
            </label>
          </div>
          <div class="form__labelGroup form__labelGroup--12">
            <label class="form__label form__label--price price">
              <div class="form__title">MINIMUM ACCEPTABLE PRICE</div>
              <div class="form__title">PER CHARTER:</div>
              <!--              <div class="form__subtitle">May be altered on a per-trip basis</div>-->
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="aircraftData.minimumAcceptablePricePerTrip"
                  v-mask="'########'"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
              <span
                v-if="$v.aircraftData.minimumAcceptablePricePerTrip.$dirty && !$v.aircraftData.minimumAcceptablePricePerTrip.required"
                class="form__field-invalid">
                This field is required
              </span>
            </label>
            <label class="form__label form__label--price price">
              <div class="form__title">MINIMUM ACCEPTABLE PRICE</div>
              <div class="form__title">PER EMPTY LEG:</div>
              <!--              <div class="form__subtitle">May be altered on a per-trip basis</div>-->
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="aircraftData.minimumAcceptablePricePerEmptyLeg"
                  v-mask="'########'"
                  :disabled="loading"
                  autocomplete="off"
                />
              </div>
              <span
                v-if="$v.aircraftData.minimumAcceptablePricePerEmptyLeg.$dirty && !$v.aircraftData.minimumAcceptablePricePerEmptyLeg.required"
                class="form__field-invalid">
                This field is required
              </span>
            </label>
          </div>
          <div style="marginBottom: 20px; line-height: 1.5"><strong>Note</strong>: An aircraft cannot be a "Bookable Demo" and part of a "Floating Fleet". Selecting "Yes" for both inputs will result in the aircraft being created as a "Bookable Demo".</div>
          <div class="form__labelGroup form__labelGroup--8">
            <div class="form__radioGroup">
              <div class="form__caption">Bookable demo?</div>
              <label class="form__label form__label--radio" :class="{'checked' : aircraftData.bookableDemo===true}">
                <input
                  class="form__radio"
                  type="radio"
                  v-model="aircraftData.bookableDemo"
                  :value="true"
                />
                <span class="form__radio-box form__radio-box--yes">Yes</span>
              </label>
              <label class="form__label form__label--radio" :class="{'checked' : aircraftData.bookableDemo===false}">
                <input
                  class="form__radio"
                  type="radio"
                  v-model="aircraftData.bookableDemo"
                  :value="false"
                />
                <span class="form__radio-box form__radio-box--no">No</span>
              </label>
            </div>
            <div class="form__radioGroup">
              <div class="form__caption">Floating fleet?</div>
              <label class="form__label form__label--radio" :class="{'checked' : aircraftData.isFloatingFleet===true}">
                <input
                  class="form__radio"
                  type="radio"
                  v-model="aircraftData.isFloatingFleet"
                  :value="true"
                />
                <span class="form__radio-box form__radio-box--yes">Yes</span>
              </label>
              <label class="form__label form__label--radio" :class="{'checked' : aircraftData.isFloatingFleet===false}">
                <input
                  class="form__radio"
                  type="radio"
                  v-model="aircraftData.isFloatingFleet"
                  :value="false"
                />
                <span class="form__radio-box form__radio-box--no">No</span>
              </label>
            </div>
          </div>
          <div class="form__labelGroup form__labelGroup--13">
            <!--            <div class="form__checkboxGroup">-->
            <!--              <label class="form__label form__label&#45;&#45;checkbox">-->
            <!--                <input-->
            <!--                  v-if="fleetType === 'detail'"-->
            <!--                  class="form__checkbox"-->
            <!--                  type="checkbox"-->
            <!--                  v-model="aircraftData.sell"-->
            <!--                  value="charter aircraft"-->
            <!--                />-->
            <!--                <input-->
            <!--                  v-else-->
            <!--                  class="form__checkbox"-->
            <!--                  type="checkbox"-->
            <!--                  v-model="aircraftData.sell"-->
            <!--                  value="charter aircraft"-->
            <!--                />-->
            <!--                <i class="form__checkbox-icon"></i>-->
            <!--                <span class="form__checkbox-caption">Sell as charter aircraft</span>-->
            <!--              </label>-->
            <!--              <label class="form__label form__label&#45;&#45;checkbox">-->
            <!--                <input-->
            <!--                  v-if="fleetType === 'detail'"-->
            <!--                  class="form__checkbox"-->
            <!--                  type="checkbox"-->
            <!--                  v-model="aircraftData.sell"-->
            <!--                  value="charter seats"-->
            <!--                />-->
            <!--                <input-->
            <!--                  v-else-->
            <!--                  class="form__checkbox"-->
            <!--                  type="checkbox"-->
            <!--                  v-model="aircraftData.sell"-->
            <!--                  value="charter seats"-->
            <!--                />-->
            <!--                <i class="form__checkbox-icon"></i>-->
            <!--                <span class="form__checkbox-caption">Sell as charter seats</span>-->
            <!--              </label>-->
            <!--            </div>-->
            <!--            <div v-if="fleetType === 'detail'" class="form__label form__label&#45;&#45;button">-->
            <!--              <Spinner/>-->
            <!--              <button-->
            <!--                class="form__button form__button&#45;&#45;edit js-editForm"-->
            <!--                type="button"-->
            <!--                @click="edit = true"-->
            <!--              >-->
            <!--                <span class="text">Change info</span>-->
            <!--              </button>-->
            <!--              <button class="form__button form__button&#45;&#45;submit" :disabled="loading">-->
            <!--                <span class="text">Save changes</span>-->
            <!--              </button>-->
            <!--            </div>-->
            <div class="form__label form__label--button">
              <Spinner/>
              <button class="form__button  form__button--submit" :disabled="loading">
                <span class="text">Save aircraft</span>
              </button>
            </div>
            <div v-if="fleetType === 'detail'" class="form__label form__label--button">
              <Spinner/>
              <button
                class="form__button  form__button--submit"
                type="button"
                :disabled="loading"
                @click="deleteAircraft"
              >
                <span class="text">Remove aircraft</span>
              </button>
            </div>
          </div>
          <div class="form__labelGroup form__labelGroup--13">
            <div v-if="fleetType === 'detail'" class="form__label form__label--button" style="margin-top: 40px">
              <Spinner/>
              <button
                class="form__button  form__button--submit blue"
                type="button"
                style="text-transform: capitalize"
                :disabled="loading"
                @click="showAvailabilitiesModal"
              >
                <span class="text">Remove Availabilities</span>
              </button>
            </div>
          </div>

        </form>
      </div>
    </vue-scroll>
    <QuestionPopup
      :name="'QuestionPopupDetail'"
      :title="'The document will be removed'"
      :text="'Are you sure?'"
      @remove="remove"
    />
    <QuestionPopup
      :name="'QuestionPopupDetailAvailabilities'"
      :title="'The availabilities will be removed'"
      :text="'Are you sure?'"
      @remove="removeAvailabilities"
    />
    <QuestionPopup
      :name="'QuestionPopupImages'"
      :title="'The image will be removed'"
      :text="'Are you sure?'"
      @remove="removeImage"
    />
  </div>
</template>

<script>
import {aircraftsAPI} from '@/api/aircrafts'
import Swiper, {Navigation, Pagination, Thumbs} from 'swiper'
import FsLightbox from 'fslightbox-vue'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import QuestionPopup from '@/components/common/modals/QuestionPopup'
import Spinner from '@/components/common/Spinner/Spinner'
import {required} from 'vuelidate/lib/validators'
// import { VMoney } from 'v-money'
import './DetailAircraft.styl'
import '@/components/common/SelectCommon/SelectCommon.styl'

Swiper.use([Navigation, Pagination, Thumbs])

export default {
  props: ['dashboardType', 'fleetType'],
  name: 'DetailAircraft',
  data: () => ({
    activeSlide: 1,
    toggler: true,
    swiper: null,
    ops: {
      scrollPanel: {
        initialScrollX: false,
        scrollingX: false,
      },
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    // money: {
    //   decimal: '.',
    //   thousands: ',',
    //   prefix: '$ ',
    //   precision: 2,
    //   masked: true
    // },
    edit: false,
    loading: false,
    aircraftData: {
      aircraftId: null,
      type: '',
      typeId: '',
      model: '',
      modelId: '',
      tailNumber: '',
      homeBase: '',
      homebaseId: '',
      documents: [],
      images: [],
      uploadedImages: [],
      sertificate: '',
      sertificateLoaded: false,
      insurance: '',
      insuranceLoaded: false,
      argusSafetyRating: '',
      wyvernSafetyRating: '',
      manufactureYear: '',
      lastIntRefurbish: '',
      lastExtRefurbish: '',
      MaxPassengers: '',
      hoursFlown: '',
      fuelBurn: '',
      speed: '',
      range: '',
      cargoCapability: '',
      wifi: null,
      numberOfTelevision: 0,
      tv: null,
      spaces: 1,
      isSpacesAvailable: true,
      pricePerHour: '',
      emptyLegsPricePerHour: '',
      minimumAcceptablePricePerTrip: '',
      minimumAcceptablePricePerEmptyLeg: '',
      bookableDemo: null,
      isFloatingFleet: null,
    },
    timeout: null,
    showTVtooltip: false,
    showSpacestooltip: true,
    typeheadMenu: null,
    activeFileForRemove: null,
    aircraftImageForRemove: null,
  }),
  validations: {
    aircraftData: {
      tailNumber: {required},
      model: {required},
      homeBase: {required},
      MaxPassengers: {required},
      speed: {required},
      range: {required},
      fuelBurn: {required},
      pricePerHour: {required},
      emptyLegsPricePerHour: {required},
      minimumAcceptablePricePerTrip: {required},
      minimumAcceptablePricePerEmptyLeg: {required},
    },
  },
  // directives: { money: VMoney },
  computed: {
    aircraft () {
      return this.$store.state.fleet.detailAircraft
    },
    shortListAircraftType () {
      return this.$store.getters.shortListAircraftType(4)
    },
    shortListAircraftModel () {
      return this.$store.getters.shortListAircraftModel(4)
    },
    shortListHomeBase () {
      return this.$store.getters.shortListHomeBase(4)
    },
  },
  updated () {
    if (this.aircraftData.tv) {
      this.showTVtooltip = true
    } else {
      this.showTVtooltip = false
    }
    if (this.aircraftData.isSpacesAvailable) {
      this.showSpacestooltip = true
    } else {
      this.showSpacestooltip = false
    }
    // if (this.aircraftData.price === 'all-price') {
    //   this.$refs.allPrice.removeAttribute('disabled')
    //   this.$refs.pricePerSeat.setAttribute('disabled', 'disabled')
    // } else {
    //   this.$refs.allPrice.setAttribute('disabled', 'disabled')
    //   this.$refs.pricePerSeat.removeAttribute('disabled')
    // }
  },
  async created () {
    if (this.fleetType === 'detail') {
      const aircraftId = this.$route.params.id
      // const aircraft = await this.$store.dispatch('getCurrentAircraft', aircraftId)

      try {
        const {data: aircraft} = await aircraftsAPI.getCurrentAircraft(aircraftId);

        this.aircraftData.aircraftId = aircraft.aircraftId
        this.aircraftData.tailNumber = aircraft.tailNumber
        this.aircraftData.images = aircraft.images
        this.aircraftData.type = aircraft.typeName
        this.aircraftData.typeId = aircraft.typeId
        this.aircraftData.model = aircraft.modelName
        this.aircraftData.modelId = aircraft.modelId
        this.aircraftData.homeBase = aircraft.homebaseName
        this.aircraftData.homebaseId = aircraft.homebaseId
        this.aircraftData.argusSafetyRating = aircraft.argusSafetyRating
        this.aircraftData.wyvernSafetyRating = aircraft.wyvernSafetyRating
        this.aircraftData.manufactureYear = aircraft.manufactureYear
        this.aircraftData.lastIntRefurbish = aircraft.lastIntRefurbish
        this.aircraftData.lastExtRefurbish = aircraft.lastExtRefurbish
        this.aircraftData.MaxPassengers = aircraft.maxPassengers
        this.aircraftData.hoursFlown = aircraft.hoursFlown
        this.aircraftData.fuelBurn = aircraft.fuelBurn
        this.aircraftData.speed = aircraft.speed
        this.aircraftData.range = aircraft.range
        this.aircraftData.cargoCapability = aircraft.cargoCapability
        this.aircraftData.wifi = aircraft.wifi
        this.aircraftData.tv = aircraft.numberOfTelevision > 0
        this.aircraftData.numberOfTelevision = aircraft.numberOfTelevision
        this.aircraftData.isSpacesAvailable = aircraft.spaces > 1
        this.aircraftData.spaces = aircraft.spaces
        this.aircraftData.pricePerHour = aircraft.pricePerHour
        this.aircraftData.emptyLegsPricePerHour = aircraft.emptyLegsPricePerHour
        this.aircraftData.minimumAcceptablePricePerTrip = aircraft.minimumAcceptablePricePerTrip
        this.aircraftData.minimumAcceptablePricePerEmptyLeg = aircraft.minimumAcceptablePricePerEmptyLeg
        this.aircraftData.bookableDemo = aircraft.bookableDemo
        this.aircraftData.isFloatingFleet = aircraft.isFloatingFleet
      } catch (e) {
        console.log(e);
      }
    }
  },
  methods: {
    async searchResultsFleet () {
      const dataType = event.currentTarget.dataset.type
      let query
      if (dataType === 'aircraftType') {
        query = this.aircraftData.type
      } else if (dataType === 'aircraftModel') {
        query = this.aircraftData.model
      } else {
        query = this.aircraftData.homeBase
      }

      const that = this

      if (this.timeout != null) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(async function () {
        this.timeout = null

        const data = {
          query: query,
          type: dataType,
          aircraftTypeId: that.aircraftData.typeId,
        }

        if (data.query) {
          await that.$store.dispatch('searchResultsFleet', data)
          if (dataType === 'aircraftType') {
            that.showTypeheadMenu('aircraftType')
          } else if (dataType === 'aircraftModel') {
            that.showTypeheadMenu('aircraftModel')
          } else {
            that.showTypeheadMenu('homeBase')
          }
        } else {
          that.$store.commit('deleteSearchResultsFleet', data)
          that.typeheadMenu = null

          if (dataType === 'aircraftType') {
            that.aircraftData.typeId = ''
          } else if (dataType === 'aircraftModel') {
            that.aircraftData.modelId = ''
          } else {
            that.aircraftData.homebaseId = ''
          }
        }
      }, 500)
    },
    showTypeheadMenu (type) {
      if (type === 'aircraftType' && this.shortListAircraftType.length) {
        this.typeheadMenu = 'aircraftType'
      } else if (type === 'aircraftModel' && this.shortListAircraftModel.length) {
        this.typeheadMenu = 'aircraftModel'
      } else if (type === 'homeBase' && this.shortListHomeBase.length) {
        this.typeheadMenu = 'homeBase'
      } else {
        this.typeheadMenu = null
      }
    },
    hideTypeheadMenu () {
      const that = this
      setTimeout(function () {
        that.typeheadMenu = null
      }, 200)
    },
    selectAircraftOptions (item) {
      const that = this
      if (event.target.getAttribute('data-type') === 'select-aircraftType') {
        this.aircraftData.typeId = item.id
        this.aircraftData.type = item.name
      } else if (event.target.getAttribute('data-type') === 'select-aircraftModel') {
        this.aircraftData.modelId = item.id
        this.aircraftData.model = item.name
      } else {
        this.aircraftData.homebaseId = item.id
        this.aircraftData.homeBase = item.name
      }

      setTimeout(function () {
        that.typeheadMenu = null
      }, 200)
    },
    async submitHandler () {
      if (
        this.$v.aircraftData.tailNumber.$invalid ||
        this.$v.aircraftData.model.$invalid ||
        this.$v.aircraftData.homeBase.$invalid ||
        this.$v.aircraftData.MaxPassengers.$invalid ||
        this.$v.aircraftData.speed.$invalid ||
        this.$v.aircraftData.range.$invalid ||
        this.$v.aircraftData.pricePerHour.$invalid ||
        this.$v.aircraftData.emptyLegsPricePerHour.$invalid ||
        this.$v.aircraftData.minimumAcceptablePricePerTrip.$invalid ||
        this.$v.aircraftData.minimumAcceptablePricePerEmptyLeg.$invalid
      ) {
        this.$v.$touch()
        return false
      }

      this.loading = true

      const photos = document.querySelectorAll('[data-id]')
      const captions = document.querySelectorAll('.js-aircraftPhotos-caption')

      const fetches = [];

      // if (this.fleetType !== 'detail') {
      photos.forEach(async (p, index) => {
        const labelTag = p.getElementsByTagName('label')[0]

        if (labelTag && labelTag.hasAttribute('style')) {
          const photoToUpload = this.aircraftData.images.filter(img => img.order === index + 1)[0];

          const formData = new FormData();
          formData.append('Name', captions[index].textContent.trim());
          formData.append('File', photoToUpload.file);
          formData.append('Order', photoToUpload.order);

          fetches.push(
            aircraftsAPI.aircraftUploadPhoto(formData)
              .then(({data}) => {
                this.aircraftData.uploadedImages.push({...data, order: photoToUpload.order})
              })
              .catch(error => console.log(error)),
          )
        }
      })
      // }

      // upload documents
      const uploadDocument = (file, type) => {
        const formData = new FormData();

        formData.append('File', file);
        formData.append('Name', file.name);
        formData.append('Type', type);

        fetches.push(
          aircraftsAPI.aircraftUploadDocuments(formData)
            .then(({data}) => {
              this.aircraftData.documents.push({
                fileName: data.fileName,
                type: type,
              });
            })
            .catch(error => console.log(error)),
        )
      }
      if (this.$refs.sertificate.files[0]) {
        uploadDocument(this.$refs.sertificate.files[0], 1);
      }
      if (this.$refs.insurance.files[0]) {
        uploadDocument(this.$refs.insurance.files[0], 2);
      }

      Promise.all(fetches)
        .then(async () => {
          const data = {
            MaxPassengers: this.aircraftData.MaxPassengers,
            aircraftId: this.aircraftData.aircraftId,
            argusSafetyRating: this.aircraftData.argusSafetyRating,
            bookableDemo: this.aircraftData.bookableDemo,
            isFloatingFleet: this.aircraftData.isFloatingFleet,
            cargoCapability: this.aircraftData.cargoCapability,
            documents: this.aircraftData.documents,
            fuelBurn: this.aircraftData.fuelBurn,
            homebaseId: this.aircraftData.homebaseId,
            hoursFlown: this.aircraftData.hoursFlown,
            images: this.aircraftData.uploadedImages,
            lastExtRefurbish: this.aircraftData.lastExtRefurbish,
            lastIntRefurbish: this.aircraftData.lastIntRefurbish,
            manufactureYear: this.aircraftData.manufactureYear,
            modelId: this.aircraftData.modelId,
            numberOfTelevision: this.aircraftData.tv ? this.aircraftData.numberOfTelevision : '0',
            spaces: this.aircraftData.isSpacesAvailable ? this.aircraftData.spaces : '1',
            isSpacesAvailable: this.aircraftData.isSpacesAvailable,
            pricePerHour: this.aircraftData.pricePerHour,
            emptyLegsPricePerHour: this.aircraftData.emptyLegsPricePerHour,
            minimumAcceptablePricePerTrip: this.aircraftData.minimumAcceptablePricePerTrip,
            minimumAcceptablePricePerEmptyLeg: this.aircraftData.minimumAcceptablePricePerEmptyLeg,
            range: this.aircraftData.range,
            speed: this.aircraftData.speed,
            tailNumber: this.aircraftData.tailNumber,
            typeId: this.aircraftData.typeId,
            wifi: this.aircraftData.wifi,
            wyvernSafetyRating: this.aircraftData.wyvernSafetyRating,
          }

          // console.log(data);

          const result = await this.$store.dispatch('createAircraft', data)

          this.loading = false

          if (result) {
            let message = {
              title: 'Aircraft Created',
              text: 'The aircraft has been created successfully',
            };

            if (data.aircraftId) {
              message = {
                title: 'Aircraft Saved',
                text: 'The aircraft has been saved successfully',
              };
            }

            this.$modal.show('ThanksPopup', {message})
            this.$router.push('/provider-dashboard/fleet')
          } else {
            const message = {
              title: 'Error',
              text: 'Something went wrong',
            }
            this.$modal.show('ThanksPopup', {message})
          }
        })
    },
    opened () {
      const popup = document.querySelector('.fslightbox-container')
      document.querySelector('body').appendChild(popup)
    },
    openLightboxOnSlide (number) {
      this.toggler = !this.toggler
    },
    getFileSertificate (event) {
      const button = document.querySelector('.js-file-button-sertificate')
      const preview = document.querySelector('.js-file-preview-sertificate')

      if (!this.aircraftData.sertificateLoaded) {
        this.$root.animations.fadeToggle(button)
        setTimeout(() => {
          this.$root.animations.fadeToggle(preview)
        }, 500)
        this.aircraftData.sertificate = this.$refs.sertificate.files[0].name
        this.aircraftData.sertificateLoaded = true
      }
    },
    getFileInsurance (event) {
      const button = document.querySelector('.js-file-button-insurance')
      const preview = document.querySelector('.js-file-preview-insurance')

      if (!this.aircraftData.insuranceLoaded) {
        this.$root.animations.fadeToggle(button)
        setTimeout(() => {
          this.$root.animations.fadeToggle(preview)
        }, 500)
        this.aircraftData.insurance = this.$refs.insurance.files[0].name
        this.aircraftData.insuranceLoaded = true
      }
    },
    // getFileSeatingChart (event) {
    //   const button = document.querySelector('.js-file-button-seating')
    //   const preview = document.querySelector('.js-file-preview-seating')
    //
    //   if (!this.aircraftData.seatingChartLoaded) {
    //     this.$root.animations.fadeToggle(button)
    //     setTimeout(() => {
    //       this.$root.animations.fadeToggle(preview)
    //     }, 500)
    //     this.aircraftData.seatingChart = this.$refs.seatingChart.files[0].name
    //     this.aircraftData.seatingChartLoaded = true
    //   }
    // },
    // getFilePrices () {
    //   const button = document.querySelector('.js-file-button-prices')
    //   const preview = document.querySelector('.js-file-preview-prices')
    //
    //   if (!this.aircraftData.pricesFileLoaded) {
    //     this.$root.animations.fadeToggle(button)
    //     setTimeout(() => {
    //       this.$root.animations.fadeToggle(preview)
    //     }, 500)
    //     this.aircraftData.pricesFile = this.$refs.pricesFile.files[0].name
    //     this.aircraftData.pricesFileLoaded = true
    //   }
    // },
    showModal (type) {
      this.$modal.show('QuestionPopupDetail')
      this.activeFileForRemove = type
    },
    showAvailabilitiesModal () {
      this.$modal.show('QuestionPopupDetailAvailabilities')
    },
    showImageModal (index) {
      const aircraftImage = this.aircraftData.images.filter(ai => ai.order === index + 1)
      if (aircraftImage.length === 1) {
        this.aircraftImageForRemove = aircraftImage[0].id
        this.$modal.show('QuestionPopupImages')
      } else {
        const message = {
          title: 'No image for removing',
        }
        this.$modal.show('ThanksPopup', {message})
      }
    },
    // REMOVE FILES
    remove () {
      switch (this.activeFileForRemove) {
        case 'sertificate':
          this.$refs.sertificate.value = ''
          this.$root.animations.fadeToggle(document.querySelector('.js-file-preview-sertificate'))
          setTimeout(() => {
            this.$root.animations.fadeToggle(document.querySelector('.js-file-button-sertificate'))
          }, 500)
          this.aircraftData.sertificateLoaded = false
          break
        case 'insurance':
          this.$refs.insurance.value = ''
          this.$root.animations.fadeToggle(document.querySelector('.js-file-preview-insurance'))
          setTimeout(() => {
            this.$root.animations.fadeToggle(document.querySelector('.js-file-button-insurance'))
          }, 500)
          this.aircraftData.insuranceLoaded = false
          break
        // case 'seatingChart':
        //   this.$refs.insurance.value = ''
        //   this.$root.animations.fadeToggle(document.querySelector('.js-file-preview-seating'))
        //   setTimeout(() => {
        //     this.$root.animations.fadeToggle(document.querySelector('.js-file-button-seating'))
        //   }, 500)
        //   this.aircraftData.insuranceLoaded = false
        //   break
        // case 'pricesFile':
        //   this.$refs.insurance.value = ''
        //   this.$root.animations.fadeToggle(document.querySelector('.js-file-preview-prices'))
        //   setTimeout(() => {
        //     this.$root.animations.fadeToggle(document.querySelector('.js-file-button-prices'))
        //   }, 500)
        //   this.aircraftData.pricesFileLoaded = false
        //   break
        default:
          return false
      }
    },
    changeSlideRequirements (item) {
      this.swiper.slideTo(item)
    },
    async deleteAircraft () {
      if (this.fleetType === 'detail') {
        this.loading = true
        try {
          await aircraftsAPI.aircraftAvailabilityRemove({aircraftId: this.aircraftData.aircraftId})

          this.$router.push('/provider-dashboard/fleet')
        } catch (e) {
          console.log(e)
          const message = {
            title: 'Aircraft Removed',
            text: 'The aircraft has been removed successfully',
          }
          this.$modal.show('ThanksPopup', {message})
        }

        this.loading = false
      }
    },
    async removeAvailabilities () {
      if (this.fleetType === 'detail') {
        this.loading = true
        try {
          await aircraftsAPI.removeAircraftAvailabilityNew({aircraftId: this.aircraftData.aircraftId})
          const message = {
            title: 'Availabilities Removed',
            text: 'The Availabilities has been removed successfully',
          }
          this.$modal.show('ThanksPopup', {message})
        } catch (e) {
          console.log(e)
          const message = {
            title: 'Availabilities not Removed',
            text: 'The Availabilities has not been removed',
          }
          this.$modal.show('ThanksPopup', {message})
        }

        this.loading = false
      }
    },
    async removeImage () {
      const result = await aircraftsAPI.removeImage(this.aircraftImageForRemove)
      if (result.data.isSuccessfull) {
        const index = this.aircraftData.images.indexOf(this.aircraftData.images.filter(ai => ai.id === this.aircraftImageForRemove)[0])
        this.aircraftData.images.splice(index, 1)
      } else {
        console.log(result.data.message)
        const message = {
          title: 'Error',
          text: 'Can not remove image',
        }
        this.$modal.show('ThanksPopup', {message})
      }
    },
    isRemoveVisible (index) {
      const aircraftImage = this.aircraftData.images.filter(ai => ai.order === index + 1)
      return aircraftImage.length === 1 && aircraftImage[0].id
    },
  },
  mounted () {
    this.swiper = new Swiper('.swiper-top', {
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    })

    this.swiper.on('slideChange', () => {
      this.activeSlide = this.swiper.activeIndex + 1
    })

    this.$root.fields.changeChecked('.form__checkbox')
    this.$root.fields.changeRadio('.form__radio')

    // upload photos
    const slider = document.querySelector('.js-aircraftPhotos')
    const inputs = slider.querySelectorAll('input[type="file"]')
    const deleteIcon = document.querySelectorAll('.form__file-remove.js-file-remove')

    const that = this

    inputs.forEach(item => {
      item.addEventListener('change', (event) => {
        const file = event.target.files[0]
        const slot = item.closest('.js-file-img')
        const name = item.closest('.js-file-img').querySelector('.js-file-name')
        const id = +item.closest('.swiper-slide').getAttribute('data-id')
        const reqItem = document.querySelectorAll('.js-aircraftPhotos-caption')
        const trash = item.closest('.js-file-img').querySelector('.js-file-remove')

        const fr = new FileReader()
        fr.addEventListener('load', function () {
          trash.classList.remove('hide')
          slot.parentElement.style.backgroundImage = "url('" + fr.result + "')"
          slot.classList.add('preview')
          name.innerHTML = file.name
          reqItem.forEach((item, index) => {
            if (index + 1 === id) {
              item.classList.add('valid')
            }
          })

          that.aircraftData.images.push({
            file: file,
            fileSrc: window.URL.createObjectURL(file),
            order: id,
          })
        }, false)
        fr.readAsDataURL(file)
      })
    })

    deleteIcon.forEach(item => {
      item.addEventListener('click', (e) => {
        e.preventDefault()

        const slide = item.closest('.swiper-slide')
        const input = slide.querySelector('input[type="file"]')
        const slot = slide.querySelector('.js-file-img')
        const name = slide.querySelector('.js-file-name')
        const id = +slide.getAttribute('data-id')
        const reqItem = document.querySelectorAll('.js-aircraftPhotos-caption')

        input.value = ''
        item.classList.add('hide')
        slot.parentElement.style.backgroundImage = 'none'
        slot.classList.remove('preview')
        name.innerHTML = 'Upload photo'
        reqItem.forEach((item, index) => {
          if (index + 1 === id) {
            item.classList.remove('valid')
          }
        })
        this.aircraftData.images[id - 1] = {}
      })
    })
  },
  destroyed () {
    this.swiper.destroy()
  },
  components: {
    FsLightbox,
    SvgIcon,
    QuestionPopup,
    Spinner,
  },
  metaInfo: {
    meta: [{
       name: 'robots', content: 'noindex',
    }],
  },
}
</script>
